import AOS from 'aos';

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */

import Alpine from 'alpinejs';

// Alpine.prefix('data-x-');
window.Alpine = Alpine;
Alpine.start();

window.addEventListener('load', (event) => {
    AOS.init({
        once: 'true',
    });
});